import { TextBadgeColor } from "@bps/fluent-ui";
import { getUniqueObjectsByKeys } from "@bps/utils";
import {
  BillingStatuses,
  InvoiceItemDto,
  InvoiceItemReferenceDto,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { InvoiceAllocationStatuses } from "@shared-types/billing/invoice-allocation-statuses.enum.ts";
import { getInvoiceItemOwing } from "@stores/billing/utils/billing.utils.ts";

export const getInvoiceReferences = (items: InvoiceItemReferenceDto[]) => {
  const invoices = items.map(x => x.invoiceItem.transaction);
  const uniqueInvoices = getUniqueObjectsByKeys({
    array: invoices,
    keys: ["id"]
  });
  return uniqueInvoices;
};
export const getInvoiceAllocationStatusBadgeColor = (
  status: InvoiceAllocationStatuses
) => {
  switch (status) {
    case InvoiceAllocationStatuses.owing:
      return TextBadgeColor.red;
    case InvoiceAllocationStatuses.paid:
      return TextBadgeColor.green;
    case InvoiceAllocationStatuses.writtenOff:
      return TextBadgeColor.lightGrey;
    case InvoiceAllocationStatuses.cancelled:
      return TextBadgeColor.lightGrey;
    case InvoiceAllocationStatuses.credited:
      return TextBadgeColor.lightGrey;
  }
};
export const getInvoiceAllocationStatuses = (options: {
  owing: number;
  paid: number;
  writtenOff: number;
  status: string;
  credited: number;
}): InvoiceAllocationStatuses[] => {
  const { owing, paid, writtenOff, status, credited } = options;
  const statuses = [
    {
      status: InvoiceAllocationStatuses.cancelled,
      filter: status === BillingStatuses.cancelled
    },
    {
      status: InvoiceAllocationStatuses.owing,
      filter: owing > 0
    },
    {
      status: InvoiceAllocationStatuses.credited,
      filter: credited > 0
    },
    {
      status: InvoiceAllocationStatuses.writtenOff,
      filter: writtenOff > 0
    },
    {
      status: InvoiceAllocationStatuses.paid,
      itemType: ItemType.Allocation,
      filter: paid > 0
    }
  ];

  return statuses.filter(x => !!x.filter).map(x => x.status);
};
export const getStatusesItem = (invoiceItem: InvoiceItemDto) => {
  const owing = getInvoiceItemOwing(invoiceItem);
  const paid = invoiceItem.paid || 0;
  const writtenOff = invoiceItem.writtenOff || 0;
  const credited = invoiceItem.credited || 0;
  const status = invoiceItem.status || "";

  return getInvoiceAllocationStatuses({
    owing,
    paid,
    writtenOff,
    status,
    credited
  });
};
