import {
  InvoiceItemReferenceDto,
  WriteOffDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { getInvoiceReferences } from "@stores/billing/utils/invoice.utils.ts";

import { Transaction } from "./Transaction.ts";

export class WriteOff extends Transaction<
  WriteOffDto,
  InvoiceItemReferenceDto
> {
  get viewPermission() {
    return Permission.WriteOffAllowed;
  }

  get viewLink() {
    return routes.accounts.invoices.writeOff.viewPath;
  }

  get invoiceReferences() {
    return getInvoiceReferences(this.items);
  }

  getStatusTooltip(): string {
    if (!this.isCancelled) return "Written-off";
    return super.getStatusTooltip();
  }
}
