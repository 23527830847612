import { TextBadgeColor } from "@bps/fluent-ui";
import { isAllocationSpenderItemDto } from "@libs/gateways/billing/billing-gateway.utils.ts";
import {
  CreditNoteDto,
  InvoiceItemReferenceDto,
  InvoiceItemView
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { getInvoiceReferences } from "@stores/billing/utils/invoice.utils.ts";

import { Transaction } from "./Transaction.ts";

export class CreditNote extends Transaction<
  CreditNoteDto,
  InvoiceItemReferenceDto
> {
  invoiceItem: InvoiceItemView;
  amount: number;
  invoiceItemId: string;
  transactionId?: string | undefined;
  get allocated() {
    return this.dto.allocated;
  }

  get allocationStatus() {
    return this.dto.allocationStatus;
  }

  get unallocated() {
    return (this.total * 1000 - (this.dto.allocated || 0) * 1000) / 1000;
  }

  get viewPermission() {
    return Permission.CreditAllowed;
  }

  get viewLink() {
    return routes.accounts.creditNotes.viewPath;
  }

  get invoiceReferences() {
    return getInvoiceReferences(this.items);
  }

  get customReceiptText() {
    if (this.unallocated > 0) {
      return "Available";
    } else return "Allocated";
  }

  get spenders() {
    return this.dto.spenders;
  }

  get creditNoteBadgeColour(): TextBadgeColor {
    if (this.unallocated > 0) {
      return TextBadgeColor.yellow;
    } else {
      return TextBadgeColor.green;
    }
  }

  get allocationSpenders() {
    return this.spenders?.filter(isAllocationSpenderItemDto);
  }

  getStatusTooltip(): string {
    if (this.allocated) return "Allocated";
    if (this.unallocated) return "Available";
    return super.getStatusTooltip();
  }
}
