import { computed } from "mobx";

import {
  ContactPreferencesDto,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class ContactPreferences extends Model<ContactPreferencesDto> {
  @computed
  get commTypePreferences() {
    return this.dto.commTypePreferences;
  }

  @computed
  get apptRemPreferences() {
    return this.dto.commTypePreferences?.find(
      x => x.commTypeCode === OutboundCommType.ApptReminder
    );
  }
  @computed
  get apptConfirmPreferences() {
    return this.dto.commTypePreferences?.find(
      x => x.commTypeCode === OutboundCommType.ApptConfirmation
    );
  }

  @computed
  get invoiceCommunicationPreferences() {
    return this.dto.commTypePreferences?.find(
      x => x.commTypeCode === OutboundCommType.Invoice
    );
  }

  @computed
  get formNotifyPreferences() {
    return this.dto.commTypePreferences?.find(
      x => x.commTypeCode === OutboundCommType.FormNotify
    );
  }

  @computed
  get administrationReminderPreferences() {
    return this.dto.commTypePreferences?.find(
      x => x.commTypeCode === OutboundCommType.AdministratorReminder
    );
  }
}
